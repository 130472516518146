import React from 'react'
import Img from 'gatsby-image'
import tw, { styled } from 'twin.macro'

const TeamCard = ({name, image, image_title, title, story, quote}) => {

  const ImageContainer = tw.div`
    h-64
    w-full
    md:w-custom
    md:h-custom
    `

    return (
      <div tw="rounded w-custom md:max-w-custom overflow-hidden my-3 mx-5 md:mx-3 bg-cylightblue bg-opacity-25 border border-cylightblue">
        <ImageContainer>
          <Img fluid={image} alt={image_title} tw="bg-cover bg-center h-full " />
        </ImageContainer>
          <div tw="px-2 pt-4">
          <h4>{name}</h4>
          <h5>{title}</h5>
          <p tw="text-sm">{story}</p>
          {quote === null ?  <p></p> : <p tw="font-bold text-sm">Favorite Saying: {quote}</p> }
        </div>
      </div>
      )

}

export default TeamCard

