import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import 'twin.macro'


import Layout from '../components/layout'
import SEO from '../components/seo'
import TeamCard from  '../components/team_card'
import ImageBox from '../components/image_box'

const About = ({data, location}) => {

  const siteTitle = data.site.siteMetadata.title
  const html = data.philosophy.copy.childMarkdownRemark.html
  const team = data.team.edges
  const teachers = data.teachers.edges


  return(
    <Layout location={location} title={siteTitle} logoImage={data.logoImage}>
      <SEO title={data.intro.headline} description={data.metaDescription.metaData} />
      <h1 tw="text-center">{data.intro.headline}</h1>
      <p tw="mx-auto font-bold text-center md:w-5/6">{data.intro.copy.copy}</p>
      <h2 tw="text-center">{data.story.headline}</h2>
      <div class="columns"><p tw="md:text-sm">{data.story.copy.copy}</p></div>

      <Img fluid={data.image.backgroundImage.fluid} alt={data.image.headline} tw="mt-12 rounded" />

      <h2 tw="text-center">{data.philosophy.headline}</h2>
      <blockquote tw="text-center">{data.philosophy.highlightedText.highlightedText}</blockquote>
       <div class="columns"><div dangerouslySetInnerHTML={{ __html: html }} tw="md:text-sm" /></div>
       <ImageBox 
        imagev={data.aboutvertical.fluid}
        imagev_alt={data.aboutvertical.description}
        imageh1={data.abouth1.fluid}
        imageh1_alt={data.abouth1.description}
        imageh2={data.abouth2.fluid}
        imageh2_alt={data.abouth2.description}
      />
      <h2 tw="text-center">Meet the Team</h2>
      <div tw="flex flex-wrap flex-row justify-center">
        {team && team.map(({node}) => {
          return(
          <TeamCard name={node.name} title={node.title} image={node.profilePhoto.fluid} image_title={node.profilePhoto.title} story={node.story.story} quote={node.quote}/>
          )
         }
        )}
      </div>

      <h2 tw="text-center">Meet Our Amazing Teachers</h2>
      <div id="teachers" tw="flex flex-wrap flex-row justify-center">
        {teachers.map(({node}) => {
          return(
          <TeamCard name={node.name}  title={node.title} image={node.profilePhoto.fluid} image_title={node.profilePhoto.title} story={node.story.story} quote={node.quote}/>
          )
         }
        )}
      </div>

    </Layout>
  )

}

export default About

export const data = graphql`
  query aboutQuery {
    site {
      siteMetadata {
        title
      }
    }
    metaDescription: contentfulSeoMetadata(title: {eq: "About > Meta Description"}) {
      metaData
    }
    logoImage:contentfulAsset(title: {eq: "logo"}) {
      fluid(quality: 100, maxWidth: 300) {
        ...GatsbyContentfulFluid
      }
    }
    intro:contentfulLayoutCopy(title: {regex: "/About > Intro/"}) {
      headline
      ctaTitle
      ctaLink
      copy {
        copy
        childMarkdownRemark {
        html
      }
      }
      title
      highlightedText {
        highlightedText
      }
    }

    story:contentfulLayoutCopy(title: {regex: "/About > Story/"}) {
      headline
      ctaTitle
      ctaLink
      copy {
        copy
        childMarkdownRemark {
        html
      }
      }
      title
      highlightedText {
        highlightedText
      }
    }

    philosophy:contentfulLayoutCopy(title: {regex: "/About > Philosophy/"}) {
      headline
      ctaTitle
      ctaLink
      copy {
        copy
        childMarkdownRemark {
        html
      }
      }
      title
      highlightedText {
        highlightedText
      }
    }

    image: contentfulLayoutHeroImage(title: {eq: "About > Image"}) {
      backgroundImage {
        fluid(quality: 100, maxWidth: 300) {
          ...GatsbyContentfulFluid
        }
      }
      headline
    }
    aboutvertical:contentfulAsset(title: {eq: "About vertical"}) {
      description
      fluid {
        ...GatsbyContentfulFluid
      }
    }
    abouth1:contentfulAsset(title: {eq: "About horizontal 1"}) {
      description
      fluid {
        ...GatsbyContentfulFluid
      }
    }
    abouth2:contentfulAsset(title: {eq: "About horizontal 2"}) {
      description
      fluid {
        ...GatsbyContentfulFluid
      }
    }
    team: allContentfulTeamBio(filter: {profilePhoto: {title: {eq: "Team"}, node_locale: {eq: "en-US"}}}, sort: {fields: order, order: ASC}) {
      edges {
        node {
          id
          title
          name
          quote
          profilePhoto {
            title
            fluid {
              ...GatsbyContentfulFluid
            }
            title
          }
          story {
            story
          }
        }
      }
    }
    teachers:allContentfulTeamBio(filter: {profilePhoto: {title: {eq: "Teacher"}, node_locale: {eq: "en-US"}}}, sort: {fields: order, order: ASC}) {
      edges {
        node {
          id
          title
          name
          quote
          profilePhoto {
            title
            fluid {
              ...GatsbyContentfulFluid
            }
            title
          }
          story {
            story
          }
        }
      }
    }
  }
  `
